@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css";
:root {
  --bs-body-color: #ccd2e9;
  --bs-body-bg: #070e27;

  --bs-table-bg: #070e27;
  --bs-table-accent-bg: #50617a;
  --bs-table-striped-bg: #ccc;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #92c398;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #92c398;
  --bs-table-hover-color: #212529;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070e27;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table {
  color: #FFFFFF;
  --bs-table-bg: #070e27;
  --bs-table-accent-bg: #50617a;
  --bs-table-striped-bg: #ccc;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #92c398;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #92c398;
  --bs-table-hover-color: #212529;
}

.table-warning {
  color: #FFFFFF;
}

.nav-link {
  color: #ccd2e9;
}

.modal-content {
  background-color: #033283;
}

/*# sourceMappingURL=index.4cff5f1d.css.map */
